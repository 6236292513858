@import '../../App.scss';

.contact {
  min-height: 92vh;

  .container {
    background-color: $light-clr;
    margin: 2rem;
    padding: 4rem;
    border-radius: 1rem;
    box-shadow: $shadow;

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
       
      h2 {
        @include title;
      }

      .underline {
        display: block;
        background-color: $purple-clr;
        width: 100px;
        height: 5px;
        border-radius: 5px;
      }
    }
    h3 {
      font-family: 'Futura Now Text', sans-serif;  
      font-size: 1.5rem;
      font-weight: 400;
      padding: 2rem;
      text-align: center;
    }

    .content {
      display: grid;
      grid-template-columns: 1fr 2fr;
      // margin: 1rem 0;
      // align-items: center;
      justify-content: center;

      .basic-contact {
        display: grid;
        grid-template-rows: repeat(3, minmax(150px, 1fr));
        align-items: center;
        // align-content: center;
        justify-content: center;
        // text-align: center;
        
  
        .contactItem {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 2rem;
          padding: 2rem;
          border-radius: 1rem;
          gap: 1rem;
          background-color: #F9E9F3;
          img {
            width: 60px;
          } 
        }
      }
  
      .contact-form {
        text-align: center;
        background-color: #e3f0f080;
        margin: 2rem auto;
        padding: 2rem;
        border-radius: 1rem;
        width: 100%;

        form {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          
          // align-items: center;
          .field-holder {
            position: relative;
            margin: 1rem;
            
            
            label {
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              cursor: text;
              transition: 0.3s ease-in-out;
            }
            input, label {
              width: 100%;
              height: 55px;
            }
            .input-field {
              background-color: transparent;
              color: $dark-gray-clr;
              border: none;
              border-bottom: 3px solid #343a4050;
              font-size: 1rem;
              outline: none;
              transition: 0.3s ease-in-out;
              
              &:focus {
                border-bottom: 3px solid red;
                + label {
                  top: -25px;
                  color: red;
                  font-size: 0.75em;
                }
              }
              &:valid {
                border-bottom: 3px solid $dark-gray-clr;
                + label {
                  top: -25px;
                  color: $dark-gray-clr;
                  font-size: 0.75em;
                }
              }
              
            }
            
            .textarea {
              background-color: transparent;
              width: 100%;
              height: 100px;
              padding-top: 1rem;
              border: none;
              border-bottom: 3px solid #343a4050;
              font-size: 1rem;
              font-family: sans-serif;
              outline: none;
              transition: 0.3s ease-in-out;
  
              &:focus {
                border-bottom: 3px solid red;
                + label {
                  top: -25px;
                  color: red;
                  font-size: 0.75em;
                }
              }
              &:valid {
                border-bottom: 3px solid $dark-gray-clr;
                + label {
                  margin-top: 1rem;
                  top: -50px;
                  color: $dark-gray-clr;
                  font-size: 0.75em;
                }
              }
              
            }
            
          }

          .btn-submit {
            background-color: $light-clr;
            color: $dark-gray-clr;
            width: 150px;
            padding: 1rem;
            margin-left: 1rem;
            cursor: pointer;
            border: 1px solid $purple-1-clr;
            font-size: 1rem;
            border-radius: 1rem;
            transition: 0.3s ease-in-out;
            box-shadow: $shadow-2d;

            &:hover {
              box-shadow: none;
              transform: translate(-5px, 5px);
            }
          }
        }
      }
    }
    
  }
}

@media screen and (max-width: 900px){
  .contact {
    .container {
      .content {
        grid-template-columns: 1fr;
      }
    }
  }
}
@media screen and (max-width: 550px){
  .contact {
    .container {
      padding: 2rem;
      h3 {
        font-size: 1.2rem;
        padding: 2rem 0;
      }
      .content {
        .basic-contact {
          .contactItem {
            margin: 0 auto 2rem;
            width: 100%;
          }
        }
        .contact-form {
          margin: 1rem;
          padding: 1rem;
          width: auto;
          form {
            .btn-submit {
              margin: auto;
            }
          }
        }
      }
    }
  }
}