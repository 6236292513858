.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  transition: all 0.3s linear;
  visibility: hidden;
  z-index: -1;
}
/* OPEN/CLOSE MODAL */
.show-modal {
  visibility: visible;
  z-index: 10;

}
.modal-box {
  overflow-y: scroll;
  background: white;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 4rem;
}
.project-title {
  font-family: 'Comfortaa';
  font-size: 3rem;
  margin: 3rem 0;
}
.modal-container {
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 4rem;
}

.project-img img{
  max-width: 100%;
  border: 5px solid #ffcad3;
  padding: 0;
}

.project-info {
  padding: 0 3.5rem;
  border-left: 3px solid rgb(240, 239, 239);
  display: grid;
  gap: 2rem;
  font-size: 1.4rem;
}

.grid-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  justify-items: center;
  gap: 1rem;
}

.modal-icon {
  color:#6495ed;
}

a {
  text-decoration: none;
  font-size: 1.2rem;
  color: #d26476;
  font-weight: 500;
  transition: color 0.5s ease;
}
a:hover {
  color: inherit;
}
.desc h3 {
  background: linear-gradient(to left, #6495ed 0%, #ffffff 50%);
  display: inline-block;
  padding: 0.25rem 1rem 0.25rem 0;
  border-radius: 0 20px 20px 0;
  margin-bottom: 1rem;
}
.desc p {
  font-size: 1.2rem;
}
.close-modal-btn, .prev-btn, .next-btn {
  position: absolute;
  top: 2rem;
  font-size: 2rem;
  padding: 5px 5px 0 5px;
  color: rgb(93, 93, 93);
  background: rgb(235, 235, 235);
  cursor: pointer;
  transition: color 0.5s ease, background 0.5s ease;
  border: transparent;
}
.close-modal-btn {
  right: 4rem;
}
.next-btn {
  right: 7rem;
}
.prev-btn {
  right: 10rem;
}

.close-modal-btn:hover, .prev-btn:hover, .next-btn:hover {
  color: #ffffff;
  background: #ffacba;
}

@media screen and (max-width: 1100px){
  
  .modal-container {
    grid-template-columns: 1fr;
  }
  .project-info {
    border: none;
  }
}
@media screen and (max-width: 600px){
  
  .modal-container {
    
    margin: 1rem;
  }
  .project-info {
    padding: 0;
  }
  .grid-item {
    word-break: break-word;
  }
}