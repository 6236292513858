@import '../../App.scss';

#about {
  position: relative;
  min-height: 92vh;

  .grid-container {
  @include grid-container;
  }


}
@media screen and (max-width: 1000px) {
  #about {
    .grid-container {
      grid-template-columns: 1fr;
      margin: 2rem;
      #profile-card {
        position: static;
        margin: 6.5rem auto 4rem auto;

        .profile-img {
          top: -70px;
          left: 0px;
        }
      }
      .about-box {
        position: static;
        width: 90%; 
        margin: auto;
      }
    }

    .grid-2 {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 500px) {
  #about {
    .grid-container {
      margin: 0;
      #profile-card {
        margin: 6.5rem auto 0 auto;
      }
    }
  }
}