@import "../../App.scss";

.portfolio-box {
  box-shadow: $shadow;
  width: 100%;
  background-color: $light-clr;
  border-radius: 20px;
  padding: 2rem;
  .title {
    @include title;
  }

  .subtitle {
    @include subtitle;
  }

  .list-projects {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 2rem;

    .project-card.card-1,
    .project-card.card-6,
    .project-card.card-11,
    .project-card.card-16,
    .project-card.card-21,
    .project-card.card-29,
    .project-card.card-34 {
      background-color: #fce1e4;
    }
    .project-card.card-2,
    .project-card.card-7,
    .project-card.card-12,
    .project-card.card-17,
    .project-card.card-22,
    .project-card.card-28,
    .project-card.card-33 {
      background-color: #fcf4dd;
    }
    .project-card.card-3,
    .project-card.card-8,
    .project-card.card-13,
    .project-card.card-18,
    .project-card.card-23,
    .project-card.card-27,
    .project-card.card-32 {
      background-color: #ddedea;
    }
    .project-card.card-4,
    .project-card.card-9,
    .project-card.card-14,
    .project-card.card-19,
    .project-card.card-24,
    .project-card.card-26,
    .project-card.card-31 {
      background-color: #e8dff5;
    }
    .project-card.card-5,
    .project-card.card-10,
    .project-card.card-15,
    .project-card.card-20,
    .project-card.card-25,
    .project-card.card-30 {
      background-color: #daeaf6;
    }
    .project-card {
      padding: 2rem;
      max-width: 100%;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      gap: 2rem;

      .img-zoom {
        height: 250px;
        overflow: hidden;
        border-radius: 20px;
        cursor: pointer;

        img {
          width: 100%;
          object-fit: cover;
          height: 250px;
          display: block;
          margin: 0 auto;
          transition: transform 0.5s ease-in-out;
        }

        &:hover img {
          transform: scale(1.5);
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .portfolio-box {
    padding: 1rem;

    .list-projects {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

      .project-card {
        padding: 1rem;
        text-align: center;
      }
    }
  }
}
