@import '../../App.scss';
@import '../../assets/css/excon.css';

.layout {
  position: relative;
  background-image: url('../../assets/images/background.jpg');
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $overlay-clr;
  }
}
#home {
  color: $light-clr;
  font-family: 'Raleway', sans-serif;
  margin: 4rem 2rem;
  z-index: 1;

  .eyebrow-heading {
    font-weight: 500;
    font-size: 2.5rem;
    padding: 2rem;
  }

  .display-name {
    font-family: 'Excon-Bold';
    font-size: 6rem;
    padding: 0 2rem;
    width: 100%;
    letter-spacing: 0.25rem;
    line-height: 1.2;
    background: linear-gradient(to right, hsl(178, 100%, 50%) 0%, hsl(228, 45%, 44%) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
    // background: linear-gradient(to right, #f32170,
    //                 #ff6b08, #cf23cf, #eedd44);
    //         -webkit-text-fill-color: transparent;
    //         -webkit-background-clip: text;
    }

  .title-role {
    font-family: 'Comfortaa', cursive;
    font-size: 3rem;
    padding: 2rem;
    
  }  
  p {
    padding: 0 2rem;
    font-weight: 500;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 700px) {
  .layout {
    background-image: none;
    background-color: $dark-gray-clr;
    align-items: baseline;
    .overlay {
      display: none;
    }
  }


  #home {
  
    .eyebrow-heading {
      font-size: 2rem;
    }
  
    .display-name {
      font-size: 4rem;
      }
    .title-role {
      font-size: 2rem;
    }  
    p {
      font-size: 1.4rem;
    }
  }
}

@media screen and (max-width: 500px) {
  #home {
    
  
    .eyebrow-heading {
      font-size: 1.5rem;
    }
  
    .display-name {
      font-size: 2.8rem;
      }
    .title-role {
      font-size: 1.5rem;
    }  
    p {
      font-size: 1rem;
    }
  }
}