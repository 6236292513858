@import '../../App.scss';

header {
  margin:  0 0 2rem ;
  nav {
    display: flex;
    justify-content: space-between;

    .hamburger-btn {
      border: none;
      background-color: $light-clr;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin: 2rem;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      display: none;
  
      .hamburger-icon {
        font-size: 1.5rem;
      }

      &:hover {
        background-color: $purple-clr;
        color: $light-clr;
      }
      
    }
    .nav-links {
      display: flex;
      justify-content: start;
      align-items: center;
  
      a {
        text-decoration: none;
        font-weight: 600;
        font-family: 'Comfortaa';
        color: $dark-gray-clr;
      }
  
      .btn {
        background-color: $light-clr;
        margin: 2rem;
        padding: 15px 30px;
        font-size: 1.2rem;
        border: 1px solid $purple-1-clr;
        border-radius: 1rem;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        box-shadow: $shadow-2d;
        &:hover {
   
          box-shadow: none;
          transform: translate(-5px, 5px);
          
      }
    }
  
      .btn-nav {
        display: flex;
        justify-content: center;
        gap: 10px;
        flex-direction: row-reverse;
        font-weight: 500;
  
        .icon {
          font-size: 1.4rem;
        }
      }
  
      .active {
        // background-color: $purple-clr;
        // color: $light-clr;
        box-shadow: none;
        transform: translate(-5px, 5px);
      }
    }
  }
}

@media screen and (max-width: 900px) {
  header{
    nav {
      justify-content: flex-end;

      .hamburger-btn {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10;
      }
    
      .nav-links {
        display: none;
      }
    
      .nav-links.expanded {
        display: block;
        background-color: $dark-gray-clr;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5;
        padding-top: 5rem;
      }
    }
  }
  
  
}