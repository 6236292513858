@import '../../App.scss';

.about-box {
  background-color: $light-clr;
  border-radius: 1rem;
  padding: 2rem;
  margin: 0 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  box-shadow: $shadow;

  .title {
    letter-spacing: 0.2rem;
    @include title;
  }

  .text {
    background-color: rgba($color: #fff3fc, $alpha: 0.7);
    border-radius: 1rem;
    color: $dark-gray-clr;
    font-size: 1.1rem;
    letter-spacing: 1px;
    line-height: 1.4;

    p {
      padding: 1rem;
      font-weight: 500;
    }
  }
  .subtitle {
    margin-top: 2rem;
    @include subtitle;
  }
  
  blockquote {
    margin: 0 2rem;
    padding: 1rem;
    border-left: $purple-clr 5px solid;
    border-radius: 5px;
    font-size: 1.4rem;
    
  

    cite {
      font-size: 1.3rem;
      display: block;
      &::before {
        content: '- ';
        padding-right: 5px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .about-box {
    box-shadow: none;
  }
}