@mixin title {
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: 'Futura Now Text', sans-serif;                             
  text-align: center;
}

@mixin grid-container {
  max-width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  }

@mixin subtitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      margin-bottom: 1rem;
      font-size: 1.5rem;
      text-transform: capitalize;
      text-align: center;
    }

    .underline {
    display: block;
    background-color: $purple-clr;
    width: 100px;
    height: 5px;
    border-radius: 5px;
  }
}

@mixin h3 {
  font-family: 'Futura Now Text', sans-serif;  
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

$dark-gray-clr: #343a40;
$light-clr: #fff;

$overlay-clr: rgba(0, 0, 0, 0.8);
$background-2-clr: hsl(217, 54%, 11%);
$background-3-clr: hsl(216, 50%, 16%);

$purple-clr: hsl(215, 51%, 70%);
$purple-1-clr: hsl(228, 45%, 44%);

$link-color: hsl(178, 100%, 50%);
$resume-clr: #46C7BF;
$border-top: 1px solid hsl(215, 32%, 27%);

$shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
$shadow-2d: -5px 5px 0px 0px $purple-1-clr;



body {
  max-width: 100%;
  min-height: 100vh;
  font-family: 'Raleway', sans-serif;
  // background-color: #daeaf6;
  background: linear-gradient(60deg, $resume-clr 0%, $purple-1-clr 100%);
}


