@import '../../App.scss';
@import '../../assets/css/excon.css';

#profile-card {
  margin: 6.2rem 5rem;
  border-radius: 1rem;
  box-shadow: $shadow;

  .profile-card {
    position: relative;
    background-color: $light-clr;
    padding: 2rem;
    border-radius: 1rem;
    margin: auto;
    text-align: center;
box-shadow: $shadow;

    .profile-img {
      margin: 0 auto;
      text-align: center;
      position: absolute;
      top: -80px;
      left: -8px;
      
  
      img {
        width: 35%;
        border-radius: 1rem;
      }
    }

    .dispaly-name {
      margin-top: 6rem;
      font-family: 'Excon-Regular';
      font-size: 2.2rem;
    }

    .career-title {
      font-family: 'Comfortaa';
      font-size: 1.5rem;
      margin: 1rem 0;
    }

    .social {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2rem 0;
      

      img {
        width: 50%;
        transition: all 0.5s ease-in-out;

        &:hover {
          transform: scale(1.05);
          transition: transform 0.5s ease-in-out;
        }
      }
    }

    .contact-info {
      background-color: #F1F1F1;
      border-radius: 1rem;
      padding: 1rem;
      text-align: left;
      font-family: 'Comfortaa';

      .contact-item {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 10px 0;
        border-bottom: 1px dotted hsla(0, 0%, 86%, 0.9);

        a {
          color: $dark-gray-clr;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
            transition: all 0.5s linear;
        }
      }

        img {
          width: 50px;
        }
      }

    }

    .download-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin: 2rem auto 0;
      border: $purple-1-clr 1px solid;
      color: $purple-1-clr;
      transition: 0.3s ease-in-out;
      box-shadow: $shadow-2d;
      border-radius: 1rem;
      padding: 15px;
      width: 200px;
      text-decoration: none;
      font-family: 'Comfortaa';

      &:hover {
        box-shadow: none;
        transform: translate(-5px, 5px);
      }

      img {
        width: 20px;
      }
    }
  }

}

@media screen and (max-width: 500px) {
  #profile-card {
    box-shadow: none;
    .profile-card {
      margin: 1.3rem;
      
      .contact-info {
        .contact-item {
          flex-direction: column;
          a {
            font-size: 1rem;
          }
        }
      }
    }
  }
}