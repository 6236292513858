@import '../../App.scss';

.btn-container {
  margin: 2rem;
  text-align: center;

  .filter-btn {
    border: none;
    background-color: transparent;
    padding: 1rem;
    font-size: 1.2rem;
    font-family: 'Excon-Bold';
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      color: $purple-1-clr;
    }
    &:active {
      color: $purple-1-clr;
    }
  }
 
}

@media screen and (max-width: 500px) {
  .btn-container {
    
    .filter-btn {
      font-size: 1rem;
      padding: 0.5rem;
    }
   
  }
}