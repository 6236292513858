@import '../../App.scss';

.resume {
  margin: 5rem;
  background-color: $light-clr;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: $shadow;

  .title {
    @include title;
  }

  .grid-2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;

    .sec-experience {
      margin: 0 auto;
      .resume-item {
        margin: 2rem;

        .resume-subtitle {
          display: flex;
          align-items: center;
          align-content: center;
          justify-items: center;
          gap: 1rem;

          h3 {
            @include h3;
            margin-bottom: 0;
          }

          img {
            width: 50px;
          }
        }

        .content {
          display: flex;
          // justify-content: center;
          gap: 1rem;
          font-weight: 600;
          margin-top: 1rem;

          .left-side {
            display: grid;
            justify-content: space-between;
            grid-template-rows: repeat(3, 1fr);
            gap: 2rem;
            text-align: right;
              span {
                font-size: 0.8rem;
                color: gray;
              }
            
          }

          .middle {
            display: grid;
            justify-content: space-between;
            grid-template-rows: repeat(3, 1fr);
            gap: 2rem;


            .dot-separator {
              display: block;

              img {
                width: 20px;
              }
            }
          }

          .right-side {
            display: grid;
            justify-content: space-between;
            grid-template-rows: repeat(3, 1fr);
            gap: 2rem;
           
              p {
                a{
                  font-size: 1em;
                  font-weight: 600;
                  color: black;
                  text-decoration: underline;
                  transition: 0.3 ease-in-out;;

                  &:hover {
                    text-decoration: none;
                  }
                  &:active {
                    color: $resume-clr;
                  }
                }
              }
             
            
          }
        }
      }
    }

    .sec-skills {
      
      .skill-item {
        margin: 2rem;
        font-size: 1.1rem;
        font-weight: 600;
      h3 {
        @include h3;
        text-align: center;
      }
      .skill-slider {
        display: grid;
            justify-content: center;
            grid-template-rows: repeat(3, 1fr);
            gap: 1rem;

            .slider {
              p {
                padding-bottom: 1rem ;
              }

              .slide {
                -webkit-appearance: none;
                display: block;
                width: 100%;
                height: 1.4rem;
                background-color: #DEF5F3;
                border-radius: 15px;

               &::-webkit-slider-thumb {
                appearance: none;
                background: $resume-clr;
                width: 25px;
                height: 25px;
                border-radius: 50%;
               }
            }
            }
      }
    }
  }

    .sec-knowledge {
      text-align: center;
      display: grid;
            align-content: start;
            grid-template-rows: repeat(3, 1fr);
            margin-top: 2rem;

      .knowledge-item {
        // margin: 2rem;

        h3 {
          @include h3;
          }
        
        .knowledge-tag {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          row-gap: 10px;
          padding: 0 1rem;
          span {
            border-radius: 10px;
            background-color: $resume-clr;
            color: $light-clr;
            font-weight: 600;
            padding: 5px;
          }
        }

        ul {
          li {
            padding: 5px;
          }
        }

        .hobbies-grid {
          display: flex;
          gap: 1rem;
          align-items: center;
          justify-content: space-between;
          text-align: center;
          margin: 0 auto;

          img {
            width: 150px;
            display: block;

          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .resume {
    text-align: center;

    .grid-2 {

      .sec-experience {
      
        .resume-item {
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
          padding: 1rem;
          border-radius: 1rem;
          .resume-subtitle {
            flex-direction: column;
            margin-bottom: 2rem;
          }

          .content {
            
            justify-content: center;
            
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .resume {
    margin: 1.3rem;
    .grid-2 {

      .sec-knowledge {
        display: block;

        .knowledge-item {
          margin-bottom: 2rem;
          .hobbies-grid {
            flex-direction: column;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .resume {
    
    .grid-2 {

      .sec-knowledge {

        .knowledge-item {
          
          .knowledge-tag {
            span {
              font-weight: 400;
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}