@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
@import '../../App.scss';

footer {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $light-clr;
  gap: 1rem;

  .signature {
    font-family: 'Allura', cursive;
    font-size: 1.4rem;
  }

  .icon {
    padding-bottom: 5px;
    font-size: 1.2rem;
  }
}

